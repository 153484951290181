import React from 'react'

class Picture extends React.Component {

  state = {
    loaded: false
  }

  componentDidMount() {
    const img = this.refs.img;
    if (img && img.complete) {
      this.onLoad()
    }
  }

  onLoad = e => {
    if (!this.state.loaded) {
      this.setState({
        loaded: true
      })
    }
  }

  render() {

    const { className, style, srcSet, aspectRatio, sizes, force, alt, theme } = this.props

    return (
      <picture className={className} style={style}>
        {!this.state.loaded && !force ? (
          <svg width={1000 * aspectRatio} height={1000} viewBox={`0 0 ${1000 * aspectRatio} 1000`}>
            <rect width={1000 * aspectRatio} height={1000} fill={theme.foreground} />
          </svg>
        ) : null}
        {srcSet ? (
          <img srcSet={srcSet} sizes={sizes} alt={alt} ref="img" onLoad={this.onLoad} style={!this.state.loaded && !force ? {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            transition: '0.5s'
          } : {
            opacity: 1,
            transition: '0.5s'
          }} />
        ) : null}
      </picture>
    )
  }
}

Picture.defaultProps = {
  className: '',
  style: {},
  srcSet: '',
  sizes: '',
  alt: '',
  aspectRatio: 1
}

export default Picture
