import React, { useState, useEffect } from "react";

const Fullscreen = ({ className, children }) => {

  const [size, setSize] = useState({
    width: window.innerWidth || document.documentElement.clientWidth,
    minHeight: window.innerHeight || document.documentElement.clientHeight
  })

  useEffect(() => {
    const handleResize = () => {
      console.log('resizing...')
      setSize({
        width: window.innerWidth || document.documentElement.clientWidth,
        minHeight: window.innerHeight || document.documentElement.clientHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return function cleanup() {
      window.removeEventListener('resize', handleResize, false)
    }
  })

  return (
    <div className={className} style={size}>
      {children}
    </div>
  )
}

export default Fullscreen
