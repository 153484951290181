import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/title";
import { AndCo } from "../../components/logo";
import SEO from "../../components/seo";
import Markdown from "../../components/markdown";
import Fullscreen from "../../components/fullscreen";
import Picture from "../../components/picture";
import { ThemeContext, DataContext, sample } from "../store";

const ProjectIndex = () => {

  // eslint-disable-next-line
  const [theme, setTheme] = useContext(ThemeContext)
  const [data] = useContext(DataContext)
  setTheme(sample(data.colours))

  const { selectedProjects } = data

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <SEO title="Projects" />

      <Fullscreen className="flex-center fs">
        <div className="container">
          <Title>Projects</Title>

          <ul className="projects">
            {selectedProjects.map((node, i) => {
              const image = node.thumbnail || node.coverImage
              const isPortrait = image && image.fluid.aspectRatio < 1
              return (
                <li className={isPortrait ? 'portrait' : 'landscape'} key={node.slug}>
                  <Link to={`/project/${node.slug}`}>
                    {image ? (
                      <Picture {...image.fluid} theme={theme} alt={node.title} />
                    ) : (
                      <Picture theme={theme} alt={node.title} />
                    )}
                    <Markdown className="outer" field={node.summary} />
                  </Link>
                </li>
              )
            })}
          </ul>

          <footer className="align-center">
            <p className="outerx4">
              <Link to="/"><em>Back to menu</em></Link>
            </p>

            <AndCo className="outerx4" fill={theme.foreground} height={29} />
          </footer>


        </div>
      </Fullscreen>
    </>
  )
};

export default ProjectIndex;
