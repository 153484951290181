import React from "react";
import { Link } from "react-router-dom";

const Title = ({ children, to }) => (
  <div className="page-title align-center outerx4 mauto">
    <Link to={to ? to : "/"} className="h2">{children}</Link>
  </div>
)

export default Title;
