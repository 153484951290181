import React, { useContext } from "react";
import Navigation from "../components/navigation";
import SEO from "../components/seo";
import Fullscreen from "../components/fullscreen";
import { ThemeContext, DataContext, sample } from "./store";

const IndexPage = () => {

  const [theme, setTheme] = useContext(ThemeContext)
  const [data] = useContext(DataContext)
  setTheme(sample(data.colours))

  return (
    <>
      <SEO />
      <Fullscreen className="flex-center fs">
        <div className="container">
          <Navigation theme={theme} instagram={data.instagram} />
        </div>
      </Fullscreen>
    </>
  )
};

export default IndexPage;
