import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Store from './store'
import Layout from '../components/layout'

import IndexPage from './index'
import AboutPage from './about/index'
import ProjectIndex from './projects/index'
import ProjectView from './projects/view'
import ContactPage from './contact/index'


class App extends React.Component {

  state = {
    initialized: false
  }

  componentDidMount() {
    // HACK: <Router> can't be rendered statically on gatsby build
    this.setState({
      initialized: true
    })
  }

  render() {
    return this.state.initialized && (
      <Store>
        <Layout>
          <Router>
            <Route path="/" exact component={IndexPage} />
            <Route path="/about" exact component={AboutPage} />
            <Route path="/projects" exact component={ProjectIndex} />
            <Route path="/project/:slug" exact component={ProjectView} />
            <Route path="/contact" exact component={ContactPage} />
          </Router>
        </Layout>
      </Store>
    )
  }
}

export default App
