import React from 'react'

const Markdown = ({className, field}) => field ? (
  <div className={className} dangerouslySetInnerHTML={{__html: field.childMarkdownRemark.html}} />
) : <div></div>

Markdown.defaultProps = {
  className: ''
}

export default Markdown
