import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "../components/logo";

function checkStorage(WrappedComponent) {

  if (process.browser && sessionStorage) {
    window.onbeforeunload = function () {
      sessionStorage.clear()
    }
    return (props) => {
      const splashSeen = sessionStorage.getItem('splashSeen');
      if (!splashSeen) {
        sessionStorage.setItem('splashSeen', true)
      }
      return <WrappedComponent {...props} splashSeen={splashSeen} />
    }
  } else {
    return (props) => <WrappedComponent {...props} splashSeen={false} />
  }
}

const Navigation = ({ instagram, theme, splashSeen }) => {

  let timer
  const [showLinks, setShowLinks] = useState(splashSeen)

  if (!showLinks) {
    timer = setTimeout(() => {
      setShowLinks(true)
      clearTimeout(timer)
    }, 1500)
  }

  useEffect(() => {
    return function cleanup() {
      clearTimeout(timer)
    }
  })

  return (
    <div className={showLinks ? 'wrapper show' : 'wrapper'}>
      <Logo className="outer" fill={theme.foreground} height={40} />
      <nav>
        <Link to="/about" className="h2">About</Link>
        <Link to="/projects" className="h2">Projects</Link>
        {instagram && <a href={instagram} className="h2" target="_blank" rel="noopener noreferrer">Instagram</a>}
        <Link to="/contact" className="h2">Contact</Link>
      </nav>
    </div>
  )
};

export default checkStorage(Navigation);
