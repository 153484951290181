import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/title";
import { AndCo } from "../../components/logo";
import SEO from "../../components/seo";
import Markdown from "../../components/markdown";
import Fullscreen from "../../components/fullscreen";
import Picture from "../../components/picture";
import { ThemeContext, DataContext } from "../store";

const ProjectView = ({ match }) => {

  // eslint-disable-next-line
  const [theme, setTheme] = useContext(ThemeContext)
  const [data] = useContext(DataContext)
  const { slug } = match.params
  const findProject = data.selectedProjects.filter((node) => node.slug === slug)
  const project = findProject[0]

  if (project.colour && theme.bg !== project.colour.background && theme.fg !== project.colour.foreground) {
    // NOTE: Needs the conditional so it doesn't get stuck in a loop.. perhaps the element using theme in the render needs to be outside...
    setTheme(project.colour)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <SEO title={project.title} />
      <Fullscreen className="flex-center fs">
        <div className="container padx2">
          <Title to="/projects">Projects</Title>

          <Markdown className="align-center outerx2" field={project.heading} />

          {project.coverImage && (
            <Picture className="outerx2" {...project.coverImage.fluid} theme={theme} />
          )}

          <Markdown className="md outerx2 guttersx2" field={project.description} />

          <footer className="align-center">
            <p className="outerx4">
              <Link to="/projects"><em>Back to projects</em></Link>
            </p>

            <AndCo to="/projects" className="outerx4" fill={theme.foreground} height={29} />
          </footer>

        </div>
      </Fullscreen>
    </>
  )
};


export default ProjectView;
