import React from 'react'
import App from '../app/app'
import SEO from "../components/seo"

const ContactPage = () => (
  <>
    <SEO title="Contact" />
    <App />
  </>
)

export default ContactPage
