import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/title";
import { AndCo } from "../../components/logo";
import SEO from "../../components/seo";
import Markdown from "../../components/markdown";
import Fullscreen from "../../components/fullscreen";
import { ThemeContext, DataContext, sample } from "../store";

const ContactPage = () => {

  // eslint-disable-next-line
  const [theme, setTheme] = useContext(ThemeContext)
  const [data] = useContext(DataContext)
  setTheme(sample(data.colours))

  return (
    <>
      <SEO title="Contact" />

      <Fullscreen className="flex-center fs">
        <div className="container">
          <Title>Contact</Title>

          <Markdown className="md align-center" field={data.contactCopy} />

          <footer className="align-center">
            <p className="outerx4">
              <Link to="/"><em>Back to menu</em></Link>
            </p>

            <AndCo className="outer" fill={theme.foreground} height={29} />
          </footer>
        </div>
      </Fullscreen>
    </>
  )
};


export default ContactPage;
