import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Title from "../../components/title";
import { AndCo } from "../../components/logo";
import SEO from "../../components/seo";
import Markdown from "../../components/markdown";
import Fullscreen from "../../components/fullscreen";
import { ThemeContext, DataContext, sample } from "../store";

const AboutPage = () => {

  // eslint-disable-next-line
  const [theme, setTheme] = useContext(ThemeContext)
  const [data] = useContext(DataContext)
  setTheme(sample(data.colours))

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  return (
    <>
      <SEO title="About" />

      <Fullscreen className="flex-center fs">
        <div className="container padx2">
          <Title>About</Title>

          <Markdown className="md" field={data.aboutCopy} />

          <footer className="align-center">
            <p className="outerx4">
              <Link to="/"><em>Back to menu</em></Link>
            </p>

            <AndCo className="outerx4" fill={theme.foreground} height={29} />
          </footer>
        </div>
      </Fullscreen>
    </>
  )
};



export default AboutPage;
